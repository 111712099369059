<template>
    <div class="form_container">
        <form @submit.prevent="formularioMacho" method="post" enctype="multipart/form-data">
            <a href="declaracion-jurada.pdf" download style="font-size: 15px; color: dodgerblue;" >CONSULTE NUESTRA POLÍTICA DE PRIVACIDAD</a> <br> <br>
              <a href="terminos-condiciones.pdf" download style="font-size: 15px; color: dodgerblue;">COSULTE AVISO LEGAL - TERMINOS DE USO</a>
              <br><br>
            <label for="fecha">FECHA DE HOY (REQUERIDO):</label>
            <input v-model="formData.fecha" type="date" id="fecha" name="fecha">
            <div v-if="errors.fecha" class="error-message">
                <ul>
                    <li v-for="error in errors.fecha">{{ error }}</li>
                </ul>
            </div>
            <br><br>
            <h2>DATOS DE LA HEMBRA</h2>
                <label for="nombre-hembra">NOMBRE COMPLETO DE LA HEMBRA, <i>como figura en el papel</i> - (REQUERIDO):</label>
                <input v-model="formData.nombre_hembra" type="text" id="nombre-hembra" name="nombre-hembra">
                <div v-if="errors.nombre_hembra" class="error-message">
                    <ul>
                        <li v-for="error in errors.nombre_hembra">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="registro-hembra">NÚMERO DE REGISTRO DE LA HEMBRA (REQUERIDO):</label>
                <input v-model="formData.registro_hembra" type="text" id="registro-hembra" name="registro-hembra">
                <div v-if="errors.registro_hembra" class="error-message">
                    <ul>
                        <li v-for="error in errors.registro_hembra">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="dueno-hembra">NOMBRE DUEÑO/A DE LA HEMBRA (REQUERIDO):</label>
                <input v-model="formData.dueno_hembra" type="text" id="dueno-hembra" name="dueno-hembra">
                <div v-if="errors.dueno_hembra" class="error-message">
                    <ul>
                        <li v-for="error in errors.dueno_hembra">{{ error }}</li>
                    </ul>
                </div>
                    <br>
            <br><br>
            <h2>DATOS DEL MACHO</h2>
                <label for="fecha-servicio">FECHA DEL SERVICIO (REQUERIDO):</label>
                <input v-model="formData.fecha_servicio" type="date" id="fecha-servicio" name="fecha-servicio">
                <div v-if="errors.fecha_servicio" class="error-message">
                    <ul>
                        <li v-for="error in errors.fecha_servicio">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="raza-macho">RAZA (REQUERIDO):</label>
                <input v-model="formData.raza_macho" type="text" id="raza-macho" name="raza-macho"> 
                <div v-if="errors.raza_macho" class="error-message">
                    <ul>
                        <li v-for="error in errors.raza_macho">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="variedad-raza">VARIEDAD DE RAZA - <i>si no hubiere una variedad, completar con "Sin Variedad" </i>- (REQUERIDO):</label>
                <input v-model="formData.variedad_raza" type="text" id="variedad-raza" name="variedad-raza"> 
                <div v-if="errors.variedad_raza" class="error-message">
                    <ul>
                        <li v-for="error in errors.variedad_raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="color">COLOR (REQUERIDO):</label>
                <input v-model="formData.color" type="text" id="color" name="color">
                <div v-if="errors.color" class="error-message">
                    <ul>
                        <li v-for="error in errors.color">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="nombre-macho">NOMBRE COMPLETO DEL MACHO, <i>como figura en el papel</i> - (REQUERIDO):</label>
                <input v-model="formData.nombre_macho" type="text" id="nombre-macho" name="nombre-macho">
                <div v-if="errors.nombre_macho" class="error-message">
                    <ul>
                        <li v-for="error in errors.nombre_macho">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="registro-macho">NÚMERO DE REGISTRO DEL MACHO (REQUERIDO):</label>
                <input v-model="formData.registro_macho" type="text" id="registro-macho" name="registro-macho">
                <div v-if="errors.registro_macho" class="error-message">
                    <ul>
                        <li v-for="error in errors.registro_macho">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="nombre">NOMBRE DEL TITULAR (REQUERIDO):</label>
                <input v-model="formData.nombre_titular" type="text" id="nombre" name="nombre">
                <div v-if="errors.nombre_titular" class="error-message">
                    <ul>
                        <li v-for="error in errors.nombre_titular">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="apellido">APELLIDO DEL TITULAR (REQUERIDO):</label>
                <input v-model="formData.apellido_titular" type="text" id="apellido" name="apellido"> 
                <div v-if="errors.apellido_titular" class="error-message">
                    <ul>
                        <li v-for="error in errors.apellido_titular">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                    <label for="documento">NÚMERO DE DOCUMENTO (REQUERIDO):</label>
                <input v-model="formData.documento_titular" type="text" id="documento" name="documento">
                <div v-if="errors.documento_titular" class="error-message">
                    <ul>
                        <li v-for="error in errors.documento_titular">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="celular">TELÉFONO CELULAR (REQUERIDO):</label>
                <input v-model="formData.celular_titular" type="tel" id="celular" name="celular">
                <div v-if="errors.celular_titular" class="error-message">
                    <ul>
                        <li v-for="error in errors.celular_titular">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="email">EMAIL (REQUERIDO):</label>
                <input v-model="formData.email" type="email" id="email" name="email" @input="validarEmail">
                <div v-if="errors.email" class="error-message">
                    <ul>
                        <li v-for="error in errors.email">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="domicilio">DOMICILIO (REQUERIDO):</label>
                <input v-model="formData.domicilio" type="text" id="domicilio" name="domicilio"> 
                <div v-if="errors.domicilio" class="error-message">
                    <ul>
                        <li v-for="error in errors.domicilio">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="codigo-postal">CODIGO POSTAL (REQUERIDO):</label>
                <input v-model="formData.codigo_postal" type="text" id="codigo-postal" name="codigo-postal"> 
                <div v-if="errors.codigo_postal" class="error-message">
                    <ul>
                        <li v-for="error in errors.codigo_postal">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="ciudad">CIUDAD (REQUERIDO):</label>
                <input v-model="formData.ciudad" type="text" id="ciudad" name="ciudad">
                <div v-if="errors.ciudad" class="error-message">
                    <ul>
                        <li v-for="error in errors.ciudad">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="provincia">PROVINCIA (REQUERIDO):</label>
                <input v-model="formData.provincia" type="text" id="provincia" name="provincia">
                <div v-if="errors.provincia" class="error-message">
                    <ul>
                        <li v-for="error in errors.provincia">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="pais">PAÍS (REQUERIDO):</label>
                <input v-model="formData.pais" type="text" id="pais" name="pais">
                <div v-if="errors.pais" class="error-message">
                    <ul>
                        <li v-for="error in errors.pais">{{ error }}</li>
                    </ul>
                </div>


                <label class="archivo" for="archivo">Adjuntar FRENTE y DORSO del Registro Macho (REQUERIDO):</label>
                <input type="file" id="frente-registro-macho" name="archivo1" @change="handleFileChange($event, 0)" multiple required>
                <br>
                <div v-if="errors[`archivos.1.0`] || errors[`archivos.1.1`]" class="error-message">
                  <ul>
                    <li v-for="error in errors[`archivos.1.0`]">{{ error }}</li>
                  </ul>
                  <ul>
                    <li v-for="error in errors[`archivos.1.1`]">{{ error }}</li>
                  </ul>
                </div>
                <label class="archivo" for="archivo">Adjuntar FRENTE y DORSO del Documento de Identidad del Titular (REQUERIDO):</label>
                <input type="file" id="frente-documento-titular" name="archivo2" @change="handleFileChange($event, 1)" multiple required>
                <br>
                <div v-if="errors[`archivos.2.0`] || errors[`archivos.2.1`]"  class="error-message">
                    <ul>
                    <li v-for="error in errors[`archivos.2.0`]">{{ error }}</li>
                  </ul>
                  <ul>
                    <li v-for="error in errors[`archivos.2.1`]">{{ error }}</li>
                  </ul>
                </div>
                <label for="terminos-y-condiciones"  id="terminos-y-condiciones" style="font-size: 15px; display: flex; align-items: center;" >
                  <input v-model="formData.terminos_condiciones"  type="checkbox" id="terminos-y-condiciones" style="margin-right: 5px;" >  
                  He leído y acepto los Términos de Uso y Política de Privacidad (REQUERIDO)
                </label>
                <div v-if="errors.terminos_condiciones" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errors.terminos_condiciones">{{ error }}</li>
                  </ul>
                </div>
                <label for="declaracion-jurada" id="declaracion-jurada" style="font-size: 15px; display: flex;" >
                  <input  v-model="formData.declaracion_jurada" type="checkbox" id="declaracion-jurada" style="margin-right: 5px;" >  
                  Declaro que los datos proporcionados son reales y están bajo condición de Declaración Jurada (REQUERIDO)
                </label>
                <div v-if="errors.declaracion_jurada" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errors.declaracion_jurada">{{ error }}</li>
                  </ul>
                </div>


                <button class="submit" type="submit">ENVIAR</button>
                <span id="mensajeJS"></span>
        </form>

        
    </div>
</template>


<script>
import axios from 'axios';
export default{
  data(){
    return{
        archivos: [[], []],
        formData: {},
        errors: {},
    };
  },

  methods: {
    handleFileChange(event, index) {
      const selectedFiles = Array.from(event.target.files);

      if (selectedFiles.length > 2) {
        // Validar que no se adjunten más de dos archivos por campo
        this.errors[`archivo${index + 1}`] = ['Por favor, adjunta hasta dos archivos.'];
        this.archivos[index] = []; // Reiniciar archivos para este campo
      } else {
        // Guardar los archivos en el índice correspondiente
        this.archivos[index] = selectedFiles;
        this.errors[`archivo${index + 1}`] = []; // Limpiar errores
      }
    },


    formularioMacho (){
        this.errors = {};
        grecaptcha.ready(() => {
            grecaptcha.execute('6Lfgx6ApAAAAALjaik-3RpTbo4eyKCF82nuu3Xgg', { action: 'submitMacho' })
            .then(token => {
                let mensaje = document.getElementById('mensajeJS');
                mensaje.textContent = 'Enviando formulario...';
                mensaje.className = 'load';
                const formData = new FormData(); 
                formData.append('g-recaptcha-response', token);
                for (let key in this.formData) {
                    formData.append(key, this.formData[key]);
                }
                this.archivos.forEach((campo, index) => {
                    campo.forEach((archivo, i) => {
                        formData.append(`archivos[${index+1}][]`, archivo); // Etiquetar archivos por campo e índice
                    });
                });
                axios.post('https://api.kennelclubdigital.com/api/formulario-macho', formData, {
                    headers: {'Content-Type': 'multipart/form-data',},
                })
                .then(response => {
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Correo enviado.';
                    mensaje.className = 'enviado'; 
                }) 
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 422) {
                            // Errores de validación
                            let mensaje = document.getElementById('mensajeJS');
                            mensaje.textContent = 'Ocurrió un error al enviar el formulario. Revise los campos.';
                            mensaje.className = 'error';
                            // Asigna los mensajes de error a cada campo correspondiente
                            this.errors = error.response.data.error;
                        }else if(error.response.status === 403){
                            mensaje.textContent = 'Fallo el recaptcha. Vuelva a intentarlo.';
                            mensaje.className = 'error';
                        }
                    }
                });
            })
        });
    }
  }

};
</script>


<style lang="scss" scoped>
.form_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form_container form {
  width: 60%; 
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

label, a{
    margin: 10px;
    margin-left: 0;
    font-weight: bold;
    color: rgb(70, 69, 69);
}
input, select{
    height: 4vh;
}

.archivo{
    margin-top: 40px;
}

fieldset{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.submit{
    cursor: pointer;
    align-self: center;
    margin: 20px;
    font-size: 1.3rem;
    font-weight: bold;
    background-color: #E19F41;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
}

.submit:hover{
    background-color: #915f1a;
}


.error-message {
  color: red;
  margin-top: 5px;
}

.enviado{
  font-weight: bold;
  color: green;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.error{
  font-weight: bold;
  color: red;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.load{
  font-weight: bold;
  color: black;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

@media (max-width: 1280px){
   h2{
    font-size: 1.3rem;
   }
   .submit{
    font-size: 15px;
  }
}

@media (max-width: 800px){
    .form_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    justify-items: center; 
    align-items: center;
  }

  .form_container form{
    width: 90%;
    padding-bottom: 0;
  }

  h2{
    font-size: 17px;
    text-wrap: balance;
  }

  h3 {
    font-size: 15px;
    text-wrap: balance;
  }

  label{
    font-size: 13px;
  }

  input{
    height: 3vh;
  }

  select{
    height: 3vh;
    font-size: 13px;
  }
  
  #terminos-y-condiciones, #declaracion-jurada{
    font-size: 11px !important;
    align-items: end !important;
  }
}
</style>