<template>
    <div class="form_container">
        <form @submit.prevent="formularioRegistroInicial" method="post" enctype="multipart/form-data">
            <a href="declaracion-jurada.pdf" download style="font-size: 15px; color: dodgerblue;" >CONSULTE NUESTRA POLÍTICA DE PRIVACIDAD</a> <br> <br>
              <a href="terminos-condiciones.pdf" download style="font-size: 15px; color: dodgerblue;">COSULTE AVISO LEGAL - TERMINOS DE USO</a>
              <br><br>
            <label for="fecha">FECHA DE HOY (REQUERIDO):</label>
            <input v-model="formData.fecha" type="date" id="fecha" name="fecha">
            <div v-if="errors.fecha" class="error-message">
                <ul>
                    <li v-for="error in errors.fecha">{{ error }}</li>
                </ul>
            </div>
            <br><br>
                <label for="nombre-ejemplar">NOMBRE DEL EJEMPLAR (REQUERIDO):</label>
                <input v-model="formData.nombre_ejemplar" type="text" id="nombre-ejemplar" name="nombre-ejemplar">
                <div v-if="errors.nombre_ejemplar" class="error-message">
                    <ul>
                        <li v-for="error in errors.nombre_ejemplar">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="fecha">FECHA DE NACIMIENTO (REQUERIDO):</label>
                <input v-model="formData.fecha_nacimiento" type="date" id="fecha-nacimiento" name="fecha">
                <div v-if="errors.fecha_nacimiento" class="error-message">
                    <ul>
                        <li v-for="error in errors.fecha_nacimiento">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="raza">RAZA (REQUERIDO):</label>
                <input v-model="formData.raza" type="text" id="raza" name="raza"> 
                <div v-if="errors.raza" class="error-message">
                    <ul>
                        <li v-for="error in errors.raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="variedad-raza">VARIEDAD DE RAZA - <i>si no hubiere una variedad, completar con "Sin Variedad" </i>- (REQUERIDO):</label>
                <input v-model="formData.variedad_raza" type="text" id="variedad-raza" name="variedad-raza"> 
                <div v-if="errors.variedad_raza" class="error-message">
                    <ul>
                        <li v-for="error in errors.variedad_raza">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="color">COLOR (REQUERIDO):</label>
                <input v-model="formData.color" type="text" id="color" name="color">
                <div v-if="errors.color" class="error-message">
                    <ul>
                        <li v-for="error in errors.color">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="pelo">TIPO DE PELO (REQUERIDO):</label>
                <input v-model="formData.pelo" type="text" id="pelo" name="pelo">
                <div v-if="errors.pelo" class="error-message">
                    <ul>
                        <li v-for="error in errors.pelo">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="opciones">SEXO (REQUERIDO)</label>
                <select v-model="formData.sexo" id="sexo" name="opciones">
                    <option value="opcion1" disabled>--Por favor, elige una opción--</option>
                    <option value="Macho">Macho</option>
                    <option value="Hembra">Hembra</option>
                </select>
                <div v-if="errors.sexo" class="error-message">
                    <ul>
                        <li v-for="error in errors.sexo">{{ error }}</li>
                    </ul>
                </div>
                <br>
                <label for="opciones">¿CÓMO OBTUVO EL PERRO? (REQUERIDO)</label>
                <select v-model="formData.como_obtuvo_perro" id="como-obtuvo-perro" name="opciones">
                    <option value="opcion1" disabled>--Por favor, elige una opción--</option>
                    <option value="Compra">Compra</option>
                    <option value="Regalo">Regalo</option>
                    <option value="Adopción">Adopción</option>
                    <option value="Rescatado de la vía pública">Rescatado de la vía pública</option>
                    <option value="Otro">Otro</option>
                </select>
                <div v-if="errors.como_obtuvo_perro" class="error-message">
                    <ul>
                        <li v-for="error in errors.como_obtuvo_perro">{{ error }}</li>
                    </ul>
                </div>
                    <br><br><br>
            <h2>DATOS DEL PROPIETARIO</h2>
                <label for="nombre">NOMBRE DEL PROPIETARIO (REQUERIDO):</label>
                <input v-model="formData.nombre" type="text" id="nombre" name="nombre">
                <div v-if="errors.nombre" class="error-message">
                    <ul>
                        <li v-for="error in errors.nombre">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="apellido">APELLIDO DEL PROPIETARIO (REQUERIDO):</label>
                <input v-model="formData.apellido" type="text" id="apellido" name="apellido"> 
                <div v-if="errors.apellido" class="error-message">
                    <ul>
                        <li v-for="error in errors.apellido">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="documento">NÚMERO DE DOCUMENTO (REQUERIDO):</label>
                <input v-model="formData.documento" type="text" id="documento" name="documento">
                <div v-if="errors.documento" class="error-message">
                    <ul>
                        <li v-for="error in errors.documento">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="telefono">TELÉFONO (REQUERIDO):</label>
                <input v-model="formData.celular" type="tel" id="telefono" name="telefono">
                <div v-if="errors.celular" class="error-message">
                    <ul>
                        <li v-for="error in errors.celular">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="email">EMAIL (REQUERIDO):</label>
                <input v-model="formData.email" type="text" id="email" name="email">
                <div v-if="errors.email" class="error-message">
                    <ul>
                        <li v-for="error in errors.email">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="domicilio">DOMICILIO (REQUERIDO):</label>
                <input v-model="formData.domicilio" type="text" id="domicilio" name="domicilio">
                <div v-if="errors.domicilio" class="error-message">
                    <ul>
                        <li v-for="error in errors.domicilio">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="codigo-postal">CÓDIGO POSTAL (REQUERIDO):</label>
                <input v-model="formData.codigo_postal" type="text" id="codigo-postal" name="codigo-postal">
                <div v-if="errors.codigo_postal" class="error-message">
                    <ul>
                        <li v-for="error in errors.codigo_postal">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="ciudad">CIUDAD / LOCALIDAD (REQUERIDO):</label>
                <input v-model="formData.ciudad" type="text" id="ciudad" name="ciudad">
                <div v-if="errors.ciudad" class="error-message">
                    <ul>
                        <li v-for="error in errors.ciudad">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="provincia">PROVINCIA (REQUERIDO):</label>
                <input v-model="formData.provincia" type="text" id="provincia" name="provincia">
                <div v-if="errors.provincia" class="error-message">
                    <ul>
                        <li v-for="error in errors.provincia">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                <label for="pais">PAIS (REQUERIDO):</label>
                <input v-model="formData.pais" type="text" id="pais" name="pais">
                <div v-if="errors.pais" class="error-message">
                    <ul>
                        <li v-for="error in errors.pais">{{ error }}</li>
                    </ul>
                </div>
                    <br>
                
                <label class="archivo" for="archivo">Adjuntar FRENTE de Documento de Identidad (REQUERIDO):</label>
                <input type="file" id="foto-documento" name="archivo1" multiple @change="handleFileChange($event, 0)" required>
                <div v-if="errors[`archivos.1.0`] || errors[`archivos.1.1`]" class="error-message">
                  <ul>
                    <li v-for="error in errors[`archivos.1.0`]">{{ error }}</li>
                  </ul>
                  <ul>
                    <li v-for="error in errors[`archivos.1.1`]">{{ error }}</li>
                  </ul>
                </div>
                <br>
                <label class="archivo" for="archivo">Adjuntar Foto del Perro - <i>Puede subir más de una imagen, si así lo requiere</i> (REQUERIDO):</label>
                <input type="file" id="foto-perro" name="archivo2" multiple @change="handleFileChange($event, 1)" required>
                <div v-if="errors[`archivos.2.0`] || errors[`archivos.2.1`]"  class="error-message">
                    <ul>
                    <li v-for="error in errors[`archivos.2.0`]">{{ error }}</li>
                  </ul>
                  <ul>
                    <li v-for="error in errors[`archivos.2.1`]">{{ error }}</li>
                  </ul>
                </div>
                <br>
        
                <h2>DATOS DEL 2° TITULAR</h2> 
                <label for="mostrarForm">¿HAY 2° TITULAR?</label>
                <select v-model="formData.mostrarForm" id="mostrarForm" name="mostrarForm">
                    <option value=Si>Sí</option>
                    <option value=No>No</option>
                </select>
                <div v-if="errors.mostrarForm" class="error-message">
                        <ul>
                        <li v-for="error in errors.mostrarForm">{{ error }}</li>
                        </ul>
                    </div>
                
                <div v-if="formData.mostrarForm === 'Si'" class="if_container">
                    <label for="opciones">TIPO DE FIRMA (REQUERIDO)</label>
                    <select v-model="formData.section" id="opciones" name="opciones">
                        <option value="" disabled selected>--Por favor, elige una opción--</option>
                        <option value="Indistinta">Indistinta</option>
                        <option value="Conjunta">Conjunta</option>
                    </select>
                    <div v-if="errors.section" class="error-message">
                        <ul>
                        <li v-for="error in errors.section">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="nombre-titular2">NOMBRE DEL 2° TITULAR (REQUERIDO):</label>
                    <input v-model="formData.nombre_titular2" type="text" id="nombre-titular2" name="nombre"> 
                    <div v-if="errors.nombre_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errors.nombre_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="apellido-titular2">APELLIDO DEL 2° TITULAR (REQUERIDO):</label>
                    <input v-model="formData.apellido_titular2" type="text" id="apellido-titular2" name="apellido"> 
                    <div v-if="errors.apellido_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errors.apellido_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="documento-titular2">NÚMERO DE DOCUMENTO 2° TITULAR (REQUERIDO):</label>
                    <input v-model="formData.documento_titular2" type="text" id="documento-titular2" name="documento">
                    <div v-if="errors.documento_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errors.documento_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="celular-titular2">TELÉFONO CELULAR (REQUERIDO):</label>
                    <input v-model="formData.celular_titular2" type="tel" id="celular-titular2" name="celular">
                    <div v-if="errors.celular_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errors.celular_titular2">{{ error }}</li>
                        </ul>
                    </div>
                        <br>
                    <label for="email-titular2">EMAIL (REQUERIDO):</label>
                    <input v-model="formData.email_titular2" type="email" id="email-titular2" name="email" @input="validarEmail">
                    <div v-if="errors.email_titular2" class="error-message">
                        <ul>
                        <li v-for="error in errors.email_titular2">{{ error }}</li>
                        </ul>
                    </div>
                    
                    <label class="archivo-titular2" for="archivotitular2">Adjuntar el documento del 2° Titular del Criadero (REQUERIDO):</label>
                    <input type="file" id="archivo" name="archivo3" @change="handleFileChange($event, 2)" multiple :required="formData.mostrarForm === 'Si'">
                    <div v-if="errors[`archivos.3.0`] || errors[`archivos.3.1`]"  class="error-message">
                    <ul>
                    <li v-for="error in errors[`archivos.3.0`]">{{ error }}</li>
                  </ul>
                  <ul>
                    <li v-for="error in errors[`archivos.3.1`]">{{ error }}</li>
                  </ul>
                </div>
                    <br>
                </div>
                <label for="terminos-y-condiciones" id="terminos-y-condiciones" style="font-size: 15px; display: flex; align-items: center;" >
                  <input v-model="formData.terminos_condiciones"  type="checkbox" id="terminos-y-condiciones" style="margin-right: 5px;" >  
                  He leído y acepto los Términos de Uso y Política de Privacidad (REQUERIDO)
                </label>
                <div v-if="errors.terminos_condiciones" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errors.terminos_condiciones">{{ error }}</li>
                  </ul>
                </div>
                <label for="declaracion-jurada" id="declaracion-jurada"  style="font-size: 15px; display: flex;" >
                  <input  v-model="formData.declaracion_jurada" type="checkbox" id="declaracion-jurada" style="margin-right: 5px;" >  
                  Declaro que los datos proporcionados son reales y están bajo condición de Declaración Jurada (REQUERIDO)
                </label>
                <div v-if="errors.declaracion_jurada" class="error-message">
                  <ul style="margin-top: 0;" >
                    <li v-for="error in errors.declaracion_jurada">{{ error }}</li>
                  </ul>
                </div>
                
                <button class="submit" type="submit">ENVIAR</button>
                <span id="mensajeJS"></span>
        </form>

        
    </div>
</template>


<script>
import axios from 'axios';
export default{
  data(){
    return{
        archivos: [[], [], []],
        formData: {},
        errors: {},
    };
  },

  methods: {

    handleFileChange(event, index) {
      const selectedFiles = Array.from(event.target.files);

      if (selectedFiles.length > 2) {
        // Validar que no se adjunten más de dos archivos por campo
        this.errors[`archivo${index + 1}`] = ['Por favor, adjunta hasta dos archivos.'];
        this.archivos[index] = []; // Reiniciar archivos para este campo
      } else {
        // Guardar los archivos en el índice correspondiente
        this.archivos[index] = selectedFiles;
        this.errors[`archivo${index + 1}`] = []; // Limpiar errores
      }
    },

    formularioRegistroInicial (){
        this.errors = {};
        grecaptcha.ready(() => {
            grecaptcha.execute('6Lfgx6ApAAAAALjaik-3RpTbo4eyKCF82nuu3Xgg', { action: 'submitRegister' })
            .then(token => {
                let mensaje = document.getElementById('mensajeJS');
                mensaje.textContent = 'Enviando formulario...';
                mensaje.className = 'load';
                const formData = new FormData(); 
                formData.append('g-recaptcha-response', token);
                for (let key in this.formData) {
                    formData.append(key, this.formData[key]);
                }
                this.archivos.forEach((campo, index) => {
                    campo.forEach((archivo, i) => {
                        formData.append(`archivos[${index+1}][]`, archivo); // Etiquetar archivos por campo e índice
                    });
                });
                axios.post('https://api.kennelclubdigital.com/api/formulario-registros-iniciales', formData, {
                    headers: {'Content-Type': 'multipart/form-data',},
                })
                .then(response => {
                    let mensaje = document.getElementById('mensajeJS');
                    mensaje.textContent = 'Correo enviado.';
                    mensaje.className = 'enviado'; 
                }) 
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 422) {
                            // Errores de validación
                            let mensaje = document.getElementById('mensajeJS');
                            mensaje.textContent = 'Ocurrió un error al enviar el formulario. Revise los campos.';
                            mensaje.className = 'error';
                            // Asigna los mensajes de error a cada campo correspondiente
                            this.errors = error.response.data.error;
                        }else if(error.response.status === 403){
                            mensaje.textContent = 'Fallo el recaptcha. Vuelva a intentarlo.';
                            mensaje.className = 'error';
                        }
                    }
                });
            })
        })
    },

  },
};
</script>


<style lang="scss" scoped>
.form_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form_container form, .if_container {
  width: 60%; 
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.if_container{
  width: 100%;
}

label, a{
    margin: 10px;
    margin-left: 0;
    font-weight: bold;
    color: rgb(70, 69, 69);
}
input, select{
    height: 4vh;
}

.archivo{
    margin-top: 40px;
}

fieldset{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.submit{
    cursor: pointer;
    align-self: center;
    margin: 20px;
    font-size: 1.3rem;
    font-weight: bold;
    background-color: #E19F41;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
}

.submit:hover{
    background-color: #915f1a;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.enviado{
  font-weight: bold;
  color: green;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.error{
  font-weight: bold;
  color: red;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

.load{
  font-weight: bold;
  color: black;
  margin: 10px auto 30px auto;
  font-size: 20px;
}

@media (max-width: 1280px){
   h2{
    font-size: 1.3rem;
   }
   .submit{
    font-size: 15px;
  }
}
@media (max-width: 800px){
    .form_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    justify-items: center; 
    align-items: center;
  }

  .form_container form{
    width: 90%;
    padding-bottom: 0;
  }

  h2{
    font-size: 17px;
    text-wrap: balance;
  }

  h3 {
    font-size: 15px;
    text-wrap: balance;
  }

  label{
    font-size: 13px;
  }

  input{
    height: 3vh;
  }

  select{
    height: 3vh;
    font-size: 13px;
  }
  
  #terminos-y-condiciones, #declaracion-jurada{
    font-size: 11px !important;
    align-items: end !important;
  }
}
</style>